import React from "react";
import "../styles/profile.scss";
import MeetTheTeamCard from "../components/meet-the-team/meet-the-team-card.component";
import Layout from "../components/layout";
import { datas } from "../data/meet-the-team-listing-data";
import Accordion from "../components/accordion/accordion.component";
import MeetTheTeamNavigation from "../components/meet-the-team/meet-the-team-navigation.component";
import Collage from "../components/meet-the-team/collage";
import { usersForMontage } from "../data/collage-people-data";

export default function UserPage() {
  return (
    <Layout title="Meet the team">
      <MeetTheTeamNavigation />
      <h1 className="main-title">Meet the team</h1>
      <div className="meet-the-team">
        <MeetTheTeamCard
          id="ourleadership"
          title={datas.titleOfOurLeadership}
          data={datas.dataOfOurLeadership}
          additionalClassName="main-card gray-bg"
        />
        <MeetTheTeamCard
          id="seniorcorpaccman"
          title={datas.titleOfSeniorCorpAccMan}
          data={datas.dataOfSeniorCorpAccMan}
          additionalClassName="main-card"
        />
        <MeetTheTeamCard
          id="headsofregion"
          title={datas.titleOfHeadsOfReg}
          data={datas.dataOfHeadsOfReg}
          additionalClassName="main-card gray-bg"
        />
        <MeetTheTeamCard
          id="businessdevman"
          loadMore={true}
          title={datas.titleOfBusinessDevMan}
          data={datas.dataOfBusinessDevMan}
          additionalClassName="main-card"
        />
        <div className="hide-on-mobile">
          <MeetTheTeamCard
            id="marketing&support"
            title={datas.titleOfMarketingAndSupport}
            data={datas.subDataOfMarketingTeam}
            subTitle={datas.subTitleOfMarketingTeam}
            additionalClassName="main-card gray-bg"
          />
          <Collage users={usersForMontage.marketingTeamMontage} className="first-montage" />
          <MeetTheTeamCard
            data={datas.subDataOfBusinessSupport}
            subTitle={datas.subTitleOfBusinessSupport}
            additionalClassName="main-card gray-bg"
          />
          <Collage users={usersForMontage.administrationTeamMontage} />
          <MeetTheTeamCard
            data={datas.subDataOfIntermediaryOperations}
            subTitle={datas.subTitleOfIntermediaryOperations}
            additionalClassName="main-card gray-bg"
          />
          <Collage users={usersForMontage.operationsTeamMontage} />
          <MeetTheTeamCard
            data={datas.subDataOfIntermediarySupportTeam}
            subTitle={datas.subTitleOfIntermediarySupportTeam}
            additionalClassName="main-card gray-bg"
          />
          <Collage users={usersForMontage.supportTeamMontage} />
        </div>
        <div className="hide-on-desktop">
          <Accordion id="marketing&support_" title={datas.titleOfMarketingAndSupport}>
            <div className="meet-the-team__card">
              <MeetTheTeamCard
                onMobile={true}
                data={datas.subDataOfMarketingTeam}
                subTitle={datas.subTitleOfMarketingTeam}
                additionalClassName="main-card "
              />
              <Collage users={usersForMontage.marketingTeamMontage} />
              <MeetTheTeamCard
                onMobile={true}
                data={datas.subDataOfBusinessSupport}
                subTitle={datas.subTitleOfBusinessSupport}
                additionalClassName="main-card gray-bg"
              />
              <Collage users={usersForMontage.administrationTeamMontage} />
              <MeetTheTeamCard
                onMobile={true}
                data={datas.subDataOfIntermediaryOperations}
                subTitle={datas.subTitleOfIntermediaryOperations}
                additionalClassName="main-card gray-bg"
              />
              <Collage users={usersForMontage.operationsTeamMontage} />
              <MeetTheTeamCard
                onMobile={true}
                data={datas.subDataOfIntermediarySupportTeam}
                subTitle={datas.subTitleOfIntermediarySupportTeam}
                additionalClassName="main-card gray-bg"
              />
              <Collage users={usersForMontage.supportTeamMontage} />
            </div>
          </Accordion>
        </div>
      </div>
    </Layout>
  );
}
