import React, { useEffect } from 'react';

export default function MeetTheTeamNavigation() {

  useEffect(() => {
    let curScroll;
    let prevScroll = window.scrollY || window.scrollTop;
    let curDirection = 0;
    let prevDirection = 0;
    let header = document.querySelector('.meet-the-team__navbar');
    let header2 = document.querySelector('.meet-the-team__navbar__dropdown');
    // Activate the hide function on this point, px.
    const threshold = 200;

    const checkScroll = () => {
      curScroll = window.scrollY || window.scrollTop;
      curDirection = (curScroll > prevScroll) ? 2 : 1;

      if (curDirection !== prevDirection) {
        changeHeader();
      }

      prevScroll = curScroll;
    };

    const changeHeader = () => {
      if (curDirection === 2 && curScroll > threshold) {
        header.classList.add('header--hide');
        header2.classList.add('header--hide');
        prevDirection = curDirection;
      } else if (curDirection === 1) {
        header.classList.remove('header--hide');
        header2.classList.remove('header--hide');
        prevDirection = curDirection;
      }
    };

    window.addEventListener('scroll', checkScroll);


    const links = document.getElementsByClassName("meet-the-team__navbar_anchor");
    for (var i = 0; i < links.length; i++) {
      links[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activated");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" activated", "");
        }
        this.className += " activated";
      });
    }

    window.onclick = (event) => {
      if (!event.target.matches('.dropbtn1')) {
        var dropdowns = document.getElementsByClassName("dropdown-content1");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('showed')) {
            openDropdown.classList.remove('showed');
          }
        }
      }
    }
  })


  const handleClick = (e) => {

    const ids = e.target.value;
    const idsWithoutHashtag = ids.replace('#', '');
    const accordionELements = document.getElementsByTagName('details');

    for (let i = 0; i < accordionELements.length; i++) {
      if (accordionELements[i].id === idsWithoutHashtag) {
        accordionELements[4].id = "marketing&support";
        accordionELements[i].setAttribute('open', '');
      } else {
        accordionELements[i].removeAttribute('open');
      }
    }

    window.location.href = ids;
  }


  return (
    <>
      <nav className="meet-the-team__navbar">
        <div className='meet-the-team__navbar-container'>
          <a className="meet-the-team__navbar_anchor" href='#ourleadership'>Our Leadership</a>

          <a className="meet-the-team__navbar_anchor" href='#seniorcorpaccman'>Corporate Account Managers</a>

          <a className="meet-the-team__navbar_anchor" href='#headsofregion'>Heads of Region</a>

          <a className="meet-the-team__navbar_anchor" href='#businessdevman'>Business Development Managers</a>

          <a className="meet-the-team__navbar_anchor" href='#marketing&support'>Marketing & Support</a>
        </div>

      </nav>
      <nav className="meet-the-team__navbar meet-the-team__navbar__dropdown">
        <div className='meet-the-team__navbar-container'>
          <select className='dropdown-select' onChange={handleClick}>
            <option value="#ourleadership_" hidden>Our Leadership</option>
            <option value="#ourleadership_">Our Leadership</option>
            <option value="#seniorcorpaccman_">Corporate Account Managers</option>
            <option value="#headsofregion_">Heads of Region</option>
            <option value="#businessdevman_">Business Development Managers</option>
            <option value="#marketing&support_">Marketing & Support</option>
          </select>
        </div>

      </nav>
    </>
  )
}
